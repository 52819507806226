import { onCleanup, onMount } from 'solid-js';
import type { AuthUser } from '../api';
import { getAnalytics } from './analytics';

export interface Props {
  user?: AuthUser;
}

export function AnalyticsTracker(props: Props) {
  const analytics = getAnalytics();
  const onLoad = () => {
    if (props.user) {
      analytics.onUserSignIn(props.user);
    }
  };

  onMount(() => {
    window.addEventListener('load', onLoad);
    analytics.startTrackingProperties();
  });

  onCleanup(() => {
    window.removeEventListener('load', onLoad);
  });

  return;
}
